<!-- Randevu Almadan Önce Ürün Seçmek İçin Kullanılacak Card Bileşeni -->
<template>

  <div>
    <b-card class="custom-card-body custom-shadow">
      <h4  class=".custom-card-header rounded text-white mb-1 pt-1 pb-1 bg-primary bg-darken-1 calendar-title">
        {{CustomerProductDetailCard.ActivityDetailCardView.activity_name}}
      </h4>
      <a href="javascript:;">
        <b-avatar  size=140  class="img-center shadow"  variant="light" :src="CustomerProductDetailCard.TrainerDetailCardView.trainer_image" />
      </a>
      <div class="pt-1 text-center">
        <h5 class="h3 title">
          <span class="d-block">{{CustomerProductDetailCard.TrainerDetailCardView.trainer_first_name
          }} {{CustomerProductDetailCard.TrainerDetailCardView.trainer_last_name
          }}</span>
          <small class="h5 font-weight-light text-muted">{{CustomerProductDetailCard.TrainerProductCardView.trainer_product_title
          }}
          </small>
        </h5>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSpinbutton,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPopover,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'CreateAppoinmentProductCard',
  components: {
    BCardBody,
    BLink,
    BBadge,
    BAvatar,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormSelect,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BAvatarGroup,
    BMediaBody,
    BFormSpinbutton,
    BAlert,
    BFormCheckbox,
    BMediaAside,
    BPopover
  },
  props: {

    CustomerProductDetailCard:{
      type:Object,
      required:true
    },
  },
  methods: {
    
  },
  computed: {

  },
}
</script>
