<template>
    <div class="row">
      <!-- TRAİNER İNFO ALANI -->
      <div class="col-lg-4 col-md-4 col-sm-12 trainer-info">
      <!-- randevu saati dışında alana tıklanıldığında seçim animasyon iptal-->
      <CreateAppoinmentProductCard  v-if="(CreateAppoinmentProductCardData !== null)" :CustomerProductDetailCard="CreateAppoinmentProductCardData"/>
        <!-- <b-card no-body class="card-apply-job">
          <b-card-body>
            <p>Value: <b>'{{ today }}'</b></p>
            <p class="mb-0">Context:</p>
            <pre class="small">{{ context }}</pre>
          </b-card-body>
        </b-card> -->
      </div>
      <!-- CALENDAR ALANI -->
      <div class="col-lg-5 col-md-5 col-sm-12">
        <b-card class="custom-card-body custom-shadow">
          <h4  class="card-custom-header  rounded text-white mb-1 pt-1 pb-1 bg-primary bg-darken-1 calendar-title">
            {{$t('Select Date')}}
         </h4>
          <b-calendar
              class="custom-calendar justify-content-center"
              v-model="today"
              block
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              id="myCalendar"
              @context="onContext"
              @selected="onSelected"
              start-weekday=1
              value-as-date
              :locale="$i18n.locale"
              :date-info-fn="dateClass"
              :date-disabled-fn="dateDisabled">
          </b-calendar>
        </b-card>
      </div>
      <!-- SAAT SEÇİM ALANI -->
      <div class="col-md-3 col-sm-12">
        <b-card class="custom-card-body custom-shadow">
          <h4  class="card-custom-header  rounded text-white mb-1 pt-1 pb-1 bg-primary bg-darken-1 calendar-title">
            {{$t('Select Appointment Time')}}
         </h4>
         <div  class="appointment-time" v-for="(appointment, index) in appointmentList" :key="index">
          
          <template>
              <b-button  class="appointment-time-button"  variant="outline-primary" @click="confirmAppointment(appointment.TimeTableId, appointment.StartDate, appointment.EndDate)"> 
                {{ startTimeFormat(appointment.StartDate) }} -
                {{ endTimeFormat(appointment.EndDate) }}
              </b-button>
          </template>
          <!-- APPOİNTMENT confirm MODAL -->
        </div>
        <div v-if="appointmentList.length <= 0">
            <b-alert
              variant="light"
              show>
                <h6 class="alert-heading bg-primary bg-darken-1 text-white rounded p-1 text-center no-appropriate-title">
                  {{$t('No Appropriate Appointments Found')}}!
                  <b-button class="btn btn-success mt-1"
                  :to="{name: 'selectProduct'}">
                    <span class="font-small-3 browse-title">{{$t('Browse Appointments for Another Activity')}}</span>
                  </b-button>
                </h6>
            </b-alert>
        </div>
        </b-card>
      </div>
    </div>
  
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardGroup,
  BCardText,
  BButton,
  BButtonGroup,
  BPaginationNav,
  BAvatar,
  BBadge,
  BMediaAside,
  BLink,
  BImg,
  BProgress,
  BProgressBar,
  BRow,
  BCalendar,
  BCardHeader,
  BMedia,
  BMediaBody,
  BCardBody,
  BAlert,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";
import SelectCalendar from "./selectCalendar.vue";
import DateFormat from "@/functions/DateFormat";
import Ripple from "vue-ripple-directive";
import CreateAppoinmentProductCard from '@/components/SelectProductCards/CreateAppointmenProductCard'
import moment from "moment";

export default {
  components: {
    CreateAppoinmentProductCard,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BCardGroup,
    BButton,
    BButtonGroup,
    BPaginationNav,
    flatPickr,
    BAvatar,
    BBadge,
    BMediaAside,
    SelectCalendar,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
    BRow,
    BCalendar,
    BCardHeader,
    BMedia,
    BMediaBody,
    BCardBody,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      myAppointments: [],
      today: new Date().getDay(),
      todayDay: null,
      appointmentList: [],
      isLoadCommingAppointment: false,
      CreateAppoinmentProductCardData: null,
       xtoday : new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),//Bugünün tarihi
      context:{
        activeYMD:moment(new Date()).format('L')  
      },
      companyBranchId:null,
      trainerProductId:null,
      activityId: null,
      trainerId: null,
    };
  },
  methods: {
    onContext(ctx) {
        this.context = ctx
        this.appointmentList = [];
      },
      onSelected(ymd,date) {
        this.getAppointmentList();
      },

    dateDisabled(ymd, date) {
        var allDay=new Date(ymd);
        var status=false
        if(allDay < this.xtoday)
          status=true;
        const day = date.getDate()
        return status;
      },

   dateClass(ymd, date) 
   {
      return  "selectDay";
  },

    confirmAppointment(time_table_id, start, end){
      this.$swal({
          title: this.startTimeFormat(start)  +" - " +  this.endTimeFormat(end),
          text: this.$t("Are you sure you want to add an appointment?"),
          icon: "success",
          showCancelButton: true,
          confirmButtonText: this.$t("Yes"),
          cancelButtonText: this.$t("No"),
          customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then((result) => 
      {
        if (result.value) 
        {
          this.spinMessage=4   
            this.is_data_load= false 
            this.$database.AppointmentService.save_customer_appointment( 
              this.$store.getters["auth/userInfo"].company_branch_id,
              this.$route.params.customerProductSalesID,
                this.$store.getters["auth/userInfo"].person_id,
              start.split('T')[0] + ' ' + start.split('T')[1],
              end.split('T')[0] + ' ' + end.split('T')[1],
              time_table_id
            ).then(res => {
                //dönen time table id değeri ile randevu bilgi ekranına yönlendir
                if (res.IsSuccess === true || res.IsSuccess === 1) {
                  this.$functions.Messages.success(
                    "Appointment registration completed successfully"
                  );
                  this.$router.push({name: 'appointmentInfo', params: {'timeTableDetailId' : res.Result.TimeTableDetailId}})
                } else {
                  this.$functions.Messages.error(res.Result);
                  this.$bvModal.hide(time_table_id);
                //  this.getMyAppointments(); //kayıtlı olduğum randevuları tekrar çek
                  this.getAppointmentList(); //müsait randevu listesini tekrar çek
                }
            })
        }
      });
    },

    createNavigationButtonEvents()
    {
      var navigtions=document.getElementById("myCalendar__calendar-nav_").querySelectorAll("button");
      navigtions.forEach(element => {
        
        element.addEventListener("click",() => 
        {
         this.getAvailableAppointmentDays();
        })});
    },

    getCreateNewAppointmentView() {
      this.$database.CustomerProductSaleService.GetCustomerProductDetail(
        this.$store.getters["auth/userInfo"].company_branch_id,
        this.$route.params.customerProductSalesID
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
            this.CreateAppoinmentProductCardData=  this.$models.createAppointmenProductCardView(
              res.Result.ActivityDetailCardView,
              res.Result.CustomerProductCardView,
              res.Result.TrainerDetailCardView,
              res.Result.TrainerProductCardView
            )
        }
      });
    },

    getAvailableAppointmentDays() {
      this.$database.AppointmentService.get_available_appointment_days(this.companyBranchId,this.activityId,this.trainerId,this.context.activeYMD
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {

            var newDataDates=[]; //karşılaştıma yapabilmek için formatlanmış tarih listesi oluştur

            if(res.Result != null && res.Result.length > 0 )
            {
                res.Result.forEach(dbDate=>{
                  var newDataDate=  moment(dbDate).format('L'); //databaseden çekilen formatlanmış tarihler
                  newDataDates.push(newDataDate)
                });

               // console.log({"Veri Tabanın Tarihler ": newDataDates});
              
                var calendarElements=  document.querySelectorAll(".selectDay"); // selectDay sınıfı ekli olan elementleri seç takvimin günleri

                calendarElements.forEach(mygrid => {
                      var  calendarDatdDate = mygrid.attributes["data-date"].value
                      var calendarDate=moment(calendarDatdDate).format('L');

                        var status= newDataDates.find(el=>el== calendarDate);

                          if(status != null)
                          {
                              var calendarId = mygrid.attributes["id"].value
                              var calendarElement=  document.getElementById(calendarId);
                                calendarElement.classList.add("start");
                          }
                  console.log(mygrid.attributes["data-date"].name +" " +  mygrid.attributes[1].name+" " +  mygrid.attributes[2].name);
            });
            }
        }
      });
    },

    //Start date tanımlar
    startTimeFormat(val) {
      let start_time = val.split("T")[1];
      start_time = start_time.split(":")[0] + ":" + start_time.split(":")[1];
      return start_time;
    },
    endTimeFormat(val) {
      //val //2021-11-30T00:59:59
      // new Date(val).getTime() + 1000
      let date = new Date(val)
      let incrementSecond = date.getTime() + 1000
      let newDate = new Date(incrementSecond)
      let formattedDateYMD = this.$functions.DateFormat(newDate).split('.').reverse().join('-')
      let hourMinuteSecond = newDate.toString().split(' ')[4]
      // let end_time = val.split("T")[1];
      // end_time = end_time.split(":")[0] + ":" + end_time.split(":")[1];
      hourMinuteSecond = hourMinuteSecond.split(':')[0] + ':' + hourMinuteSecond.split(':')[1]
      return hourMinuteSecond;
    },
    returnToday() {
      this.today = new Date();
      this.todayDay = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][new Date().getDay()];
    },
    getAppointmentList() {

      this.$database.AppointmentService.get_appointment_list({
        CompanyBranchId: this.$store.getters["auth/userInfo"].company_branch_id,
        TrainerProductId: this.$route.params.trainerProductID,
        ActivityId: this.$route.params.activityID,
        TrainerId: this.$route.params.trainerID,
        PersonId:this.$store.getters["auth/userInfo"].person_id,
        StartDate: this.$functions.ConvertToEnglishDateFormat(this.formattedDayStart),
        EndDate: this.$functions.ConvertToEnglishDateFormat(this.formattedDayEnd),
        SelectDate:moment(this.today).format('L')
      }).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          this.appointmentList = res.Result;
        } else {
          this.appointmentList = [];
        }
      });    
    },

    getData()
    {
        console.log(context.activeYMD);
    }


  },
  computed: {
    formattedDay() {
      return DateFormat(this.today);
    },
    formattedDayReverse() {
      return DateFormat(this.today).split(".").reverse().join(".");
    },
    formattedDayStart() {
      return DateFormat(this.today) + " 00:00:00";
    },
    formattedDayEnd() {
      return DateFormat(this.today.getTime()) + " 23:59:59";
    },

    formattedDayText() {
      let day = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][this.today.getDay()];
      return this.$t(day);
    },


    

  },
  watch: {
  },

  created() {

    this.companyBranchId= this.$store.getters["auth/userInfo"].company_branch_id,
    this.trainerProductId= this.$route.params.trainerProductID,
    this.activityId= this.$route.params.activityID,
    this.trainerId= this.$route.params.trainerID,
    this.returnToday();

    this.getAppointmentList();
    this.getCreateNewAppointmentView();
    this.getAvailableAppointmentDays();
   
  },

  mounted()
  {
    window.addEventListener('load', function () {
      var element = document.getElementsByClassName('b-calendar-inner')
      this.calendarColId = element.item(0).getAttribute('id')
      this.calendarColId = this.calendarColId.split('__')[2]
    })
        this.createNavigationButtonEvents() 
  },
};
</script>

<!-- stillendirme-->
<style src="@/styles/css/makeAppointment.css"></style>
<style lang="scss" src="@/styles/scss/makeAppointment.scss" scoped></style>